<template>
    <div class="px-4 text-14 pb-5">
        <form class="p-fluid pt-2" @submit.prevent="">
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Nama Negara *
                </label>
                <InputText type="text" placeholder="Nama Negara" v-model="form.name" :disabled="form.is_submit" />
            </div>
        </form>
    </div>
</template>

<script>
export default {
    props: ['data', 'services'],
    data() {
        return {
            form: {
                id: null,
                name: '',
                is_submit: false,
            },
        }
    },
    watch: {
        'form': {
            handler: function() {
                this.$emit('updated', this.form)
            },
            deep: true,
        },
    },
    mounted() {
        if (this.data) {
            this.form = {...this.form, ...this.data}
        }
    },
    methods: {
        
    },
}
</script>

<style lang="scss" scoped></style>
